<template>
    <v-container fluid class="py-0 px-0" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
        >
            <v-tab
                :to="{
                    name: 'purchasing-requests',
                }"
            >
                REQUESTS
            </v-tab>
            <v-tab
                v-if="pricingPermission"
                :to="{
                    name: 'purchasing-pricing',
                }"
            >
                PRICING
            </v-tab>
            <v-tab
                v-if="preApprovalPermission"
                :to="{
                    name: 'purchasing-pre-approval',
                }"
            >
                PRE-APPROVAL
            </v-tab>
            <v-tab
                v-if="approvalPermission"
                :to="{
                    name: 'purchasing-approval',
                }"
            >
                APPROVAL
            </v-tab>
            <v-tab
                v-if="pricingPermission"
                :to="{
                    name: 'purchasing-process',
                }"
            >
                PURCHASING
            </v-tab>
            <v-tab
                v-if="inTransitPermission"
                :to="{
                    name: 'purchasing-in-transit',
                }"
            >
                IN TRANSIT
            </v-tab>
            <v-tab
                v-if="receivedPermission"
                :to="{
                    name: 'purchasing-received',
                }"
            >
                RECEIVED
            </v-tab>
            <v-tab
                v-if="closedPermission || allClosedPermission"
                :to="{
                    name: 'purchasing-closed',
                }"
            >
                CLOSED
            </v-tab>
            <v-tab
                v-if="allPurchasesPermission"
                :to="{
                    name: 'purchasing-rejected-log',
                }"
            >
                CANCELED
            </v-tab>
            <v-tab
                v-if="assignBuyerPermission"
                :to="{
                    name: 'purchasing-assign',
                }"
            >
                ASSIGN
            </v-tab>
            <v-tab
                v-if="purchasesStatusPermission"
                :to="{
                    name: 'purchasing-status',
                }"
            >
                STATUS
            </v-tab>
            <v-tab
                v-if="purchasesStatisticsPermission"
                :to="{
                    name: 'purchases-statistics',
                }"
            >
                STATISTICS
            </v-tab>
            <v-tab
                v-if="purchasesStatisticsPermission"
                :to="{
                    name: 'purchases-indicators',
                }"
            >
                INDICATORS
            </v-tab>
        </v-tabs>
        <v-card flat>
            <v-card-text
                class="overflow-y-auto px-4 pt-4"
                :style="`height: ${height}px`"
            >
                <router-view />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import API from '@/services/api'

export default {
    name: 'StockRoom',
    data: () => ({
        loading: true,
        height: 0,
        pricingPermission: undefined,
        preApprovalPermission: undefined,
        approvalPermission: undefined,
        inTransitPermission: undefined,
        closedPermission: undefined,
        assignBuyerPermission: undefined,
        receivedPermission: undefined,
        allClosedPermission: undefined,
        purchasesStatusPermission: undefined,
        purchasesStatisticsPermission: undefined,
        allPurchasesPermission: undefined,
    }),
    async mounted() {
        try {
            this.loading = true
            this.onResize()
            const {
                data: { user },
            } = await API.getMyInfo()
            this.pricingPermission = user.permissions.includes('pricing')
            this.preApprovalPermission = user.permissions.includes(
                'preApproval'
            )
            this.approvalPermission = user.permissions.includes('finalApproval')
            this.inTransitPermission = user.permissions.includes(
                'inTransitPurchase'
            )
            this.closedPermission = user.permissions.includes('closedPurchases')
            this.assignBuyerPermission = user.permissions.includes(
                'assignBuyer'
            )
            this.receivedPermission = user.permissions.includes(
                'receivedPurchase'
            )
            this.allClosedPermission = user.permissions.includes(
                'allClosedPurchases'
            )
            this.purchasesStatusPermission = user.permissions.includes(
                'purchasesStatus'
            )
            this.purchasesStatisticsPermission = user.permissions.includes(
                'purchasesStatistics'
            )
            this.allPurchasesPermission = user.permissions.includes(
                'allPurchases'
            )
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
            } = this.$refs
            this.height = window.innerHeight - containerHeight - tabsHeight - 25
        },
    },
}
</script>

<style></style>
