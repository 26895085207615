var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{ref:"container",staticClass:"py-0 px-0",attrs:{"fluid":""}},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":""}},[_c('v-tab',{attrs:{"to":{
                name: 'purchasing-requests',
            }}},[_vm._v(" REQUESTS ")]),(_vm.pricingPermission)?_c('v-tab',{attrs:{"to":{
                name: 'purchasing-pricing',
            }}},[_vm._v(" PRICING ")]):_vm._e(),(_vm.preApprovalPermission)?_c('v-tab',{attrs:{"to":{
                name: 'purchasing-pre-approval',
            }}},[_vm._v(" PRE-APPROVAL ")]):_vm._e(),(_vm.approvalPermission)?_c('v-tab',{attrs:{"to":{
                name: 'purchasing-approval',
            }}},[_vm._v(" APPROVAL ")]):_vm._e(),(_vm.pricingPermission)?_c('v-tab',{attrs:{"to":{
                name: 'purchasing-process',
            }}},[_vm._v(" PURCHASING ")]):_vm._e(),(_vm.inTransitPermission)?_c('v-tab',{attrs:{"to":{
                name: 'purchasing-in-transit',
            }}},[_vm._v(" IN TRANSIT ")]):_vm._e(),(_vm.receivedPermission)?_c('v-tab',{attrs:{"to":{
                name: 'purchasing-received',
            }}},[_vm._v(" RECEIVED ")]):_vm._e(),(_vm.closedPermission || _vm.allClosedPermission)?_c('v-tab',{attrs:{"to":{
                name: 'purchasing-closed',
            }}},[_vm._v(" CLOSED ")]):_vm._e(),(_vm.allPurchasesPermission)?_c('v-tab',{attrs:{"to":{
                name: 'purchasing-rejected-log',
            }}},[_vm._v(" CANCELED ")]):_vm._e(),(_vm.assignBuyerPermission)?_c('v-tab',{attrs:{"to":{
                name: 'purchasing-assign',
            }}},[_vm._v(" ASSIGN ")]):_vm._e(),(_vm.purchasesStatusPermission)?_c('v-tab',{attrs:{"to":{
                name: 'purchasing-status',
            }}},[_vm._v(" STATUS ")]):_vm._e(),(_vm.purchasesStatisticsPermission)?_c('v-tab',{attrs:{"to":{
                name: 'purchases-statistics',
            }}},[_vm._v(" STATISTICS ")]):_vm._e(),(_vm.purchasesStatisticsPermission)?_c('v-tab',{attrs:{"to":{
                name: 'purchases-indicators',
            }}},[_vm._v(" INDICATORS ")]):_vm._e()],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"overflow-y-auto px-4 pt-4",style:(`height: ${_vm.height}px`)},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }